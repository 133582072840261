import Layout from "../../../../src/components/layout";
import SEO from "../../../../src/components/seo";
import Greeting from "../../../../src/components/Greeting";
import greetings from "../../../../src/data/greetings";
import * as React from 'react';
export default {
  Layout,
  SEO,
  Greeting,
  greetings,
  React
};